import React, { useState } from 'react';
import PropTypes from 'prop-types';

import OptionallyClickable from '../../library/optionally-clickable/optionally-clickable';
import Typography from '../../library/typography/typography';
import TireRuggedSolidIcon from '../../shared/icons/tire-rugged-solid-icon';
import StoreQuickView from '../store-quick-view/store-quick-view';

import { getStoreDataLayerValues } from '../../shared/utils/store/store';

import { SERVICE_CODE } from '../../shared/constants/store';

import './store-quick-view-list.scss';

export const LIST_TYPE = {
  COLLAPSIBLE: 'COLLAPSIBLE',
  MINIMAL: 'MINIMAL',
  STANDARD: 'STANDARD'
};

function StoreQuickViewList(props) {
  const {
    cartReconciliationWarningMessage,
    className,
    hideReadReviews = false,
    isStoreSelectionDisabled,
    listNumberOffset,
    listType = LIST_TYPE.STANDARD,
    onCallStore,
    onReadReviewClick,
    onSelectStore,
    onStoreDetailsLinkClick,
    onGetDirectionsClick,
    onNearbyStoreClick,
    onSetStoreClick,
    onScheduleAppointmentClick,
    onShopProductsClick,
    productItem,
    selectStoreButtonLabel,
    selectedStoreIdx,
    shouldSetStoreAndCart,
    shouldSummarizePackages,
    showAdditionalServicesBanner,
    showAppointmentCTA,
    storeRef,
    stores
  } = props;
  const [expandedIds, setExpandedIds] = useState([]);
  const isMinimalList = listType === LIST_TYPE.MINIMAL;
  const isCollapsibleList = listType === LIST_TYPE.COLLAPSIBLE;

  if (!Array.isArray(stores)) {
    return null;
  }

  function toggle(id) {
    let newExpandedIds = [];

    if (expandedIds.includes(id)) {
      newExpandedIds = expandedIds.filter(eId => eId !== id);
    } else {
      newExpandedIds = [...expandedIds, id];
    }

    setExpandedIds(newExpandedIds);
  }

  function filterMinimalStoreProps(store) {
    return {
      address: store?.address,
      distance: store?.distance,
      geoPoint: store?.geoPoint,
      legacyStoreCode: store?.legacyStoreCode,
      rating: store?.rating,
      storeCode: store?.storeCode
    };
  }

  return (
    <ol className={className} styleName="container">
      {stores.map((store, idx) => {
        const isExpanded = expandedIds.includes(store?.storeCode);
        let styleName = 'list-item';

        if (isMinimalList) styleName = 'list-item-minimal';
        if (isCollapsibleList && selectedStoreIdx === idx + 1)
          styleName += ' list-item--selected';
        if (isExpanded) styleName += ' list-item--open';

        const storeDataLayerValues = getStoreDataLayerValues(store);
        const listItemNumber = (listNumberOffset || 0) + idx + 1;
        const onStoreSelectedClick =
          onNearbyStoreClick && (() => onNearbyStoreClick(idx + 1));

        return (
          <li
            key={`sqvl-${idx}`}
            ref={
              storeRef && storeRef.current
                ? el => (storeRef.current[idx] = el)
                : null
            }
            styleName={styleName}
          >
            {showAdditionalServicesBanner &&
              store?.additionalServices.some(
                service => service.code === SERVICE_CODE.COMMERCIAL_VEHICLE
              ) && (
                <div styleName="services-message-container">
                  <TireRuggedSolidIcon styleName="icon" />
                  <span styleName="services-message">
                    This store services 19.5” tires
                  </span>
                </div>
              )}
            <div styleName="quick-view-container">
              <div styleName="list-index">
                <OptionallyClickable onClick={onStoreSelectedClick}>
                  <Typography size="small" styleName="list-number">
                    {listItemNumber}
                  </Typography>
                </OptionallyClickable>
              </div>
              <StoreQuickView
                cartReconciliationWarningMessage={
                  cartReconciliationWarningMessage
                }
                hidePhone={isMinimalList && !isExpanded}
                hideReadReviews={
                  hideReadReviews
                    ? hideReadReviews
                    : isMinimalList && !isExpanded
                }
                isCollapsible={listType === LIST_TYPE.COLLAPSIBLE}
                isPitPassStore={store?.pitPass}
                isStoreSelectionDisabled={isStoreSelectionDisabled}
                onAddressClick={onStoreSelectedClick}
                onCallStore={() => onCallStore?.(storeDataLayerValues)}
                onDistanceClick={onStoreSelectedClick}
                onGetDirectionsClick={() =>
                  onGetDirectionsClick?.(storeDataLayerValues)
                }
                onReadReviewClick={() =>
                  onReadReviewClick?.(storeDataLayerValues)
                }
                onScheduleAppointmentClick={() =>
                  onScheduleAppointmentClick?.(storeDataLayerValues)
                }
                onSelectStore={onSelectStore}
                onSetStoreClick={onSetStoreClick}
                onShopProductsClick={() =>
                  onShopProductsClick?.(storeDataLayerValues)
                }
                onStoreDetailsLinkClick={() =>
                  onStoreDetailsLinkClick?.(storeDataLayerValues)
                }
                productItem={productItem}
                selectStoreButtonLabel={selectStoreButtonLabel}
                shouldSetStoreAndCart={shouldSetStoreAndCart}
                shouldSummarizePackage={shouldSummarizePackages}
                showAltScheduleAppointmentCTA={showAppointmentCTA}
                {...(!isMinimalList || isExpanded
                  ? store
                  : filterMinimalStoreProps(store))}
              />
              {isMinimalList && (
                <div styleName="list-actions">
                  <button
                    onClick={() => toggle(store.storeCode)}
                    styleName={isExpanded ? 'toggle-expanded' : 'toggle'}
                    type="button"
                  />
                </div>
              )}
            </div>
          </li>
        );
      })}
    </ol>
  );
}

StoreQuickViewList.propTypes = {
  cartReconciliationWarningMessage: PropTypes.string,
  className: PropTypes.string,
  hideReadReviews: PropTypes.bool,
  isStoreSelectionDisabled: PropTypes.bool,
  listNumberOffset: PropTypes.number,
  listType: PropTypes.oneOf(LIST_TYPE),
  onCallStore: PropTypes.func,
  onGetDirectionsClick: PropTypes.func,
  onNearbyStoreClick: PropTypes.func,
  onReadReviewClick: PropTypes.func,
  onScheduleAppointmentClick: PropTypes.func,
  onSelectStore: PropTypes.func,
  onSetStoreClick: PropTypes.func,
  onShopProductsClick: PropTypes.func,
  onStoreDetailsLinkClick: PropTypes.func,
  productItem: PropTypes.object,
  selectStoreButtonLabel: PropTypes.string,
  selectedStoreIdx: PropTypes.number,
  shouldSetStoreAndCart: PropTypes.bool,
  shouldSummarizePackages: PropTypes.bool,
  showAdditionalServicesBanner: PropTypes.bool,
  showAppointmentCTA: PropTypes.bool,
  showChooseThisStoreCTA: PropTypes.bool,
  showMakeMyStoreCTA: PropTypes.bool,
  showPitPassBanner: PropTypes.bool,
  storeRef: PropTypes.shape({ current: PropTypes.object }),
  stores: PropTypes.arrayOf(PropTypes.object)
};

export default StoreQuickViewList;
