import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import Button from '../../library/button/button';
import Image from '../../library/image/image';

import { getAffiliateMarketingParams } from '../../shared/utils/url-params/url-params';

import './store-locator-message.scss';

function StoreLocatorMessage(props) {
  const { isValidSearch, latestSearch } = props;
  const affiliateMarketingUrlParams = queryString.stringify(
    getAffiliateMarketingParams()
  );

  let urlParams = affiliateMarketingUrlParams
    ? `?${affiliateMarketingUrlParams}`
    : '';

  return (
    <div styleName="message-container">
      <Image
        alt="Tire Rack Logo"
        loading="lazy"
        src="https://www.tirerack.com/content/dam/tirerack/desktop/Navigation/TireRackLogo.png"
        styleName="logo"
      />
      <div styleName="content">
        <>
          {isValidSearch ? (
            <p>
              The nearest store to &quot;{latestSearch}&quot; is more than
              &nbsp;
              <strong>250 miles</strong> away.
            </p>
          ) : (
            <p>There are no stores to show based on your query.</p>
          )}
          <p>
            Head to Tire Rack to shop for tires, wheels, and accessories online
            with local installation options across the nation.
          </p>
        </>
      </div>
      <Button
        href={`${process.env.__TIRE_RACK_HOST__}${urlParams}`}
        isSecondary
        styleName="shop-button"
      >
        Shop Tire Rack
      </Button>
    </div>
  );
}

StoreLocatorMessage.propTypes = {
  isValidSearch: PropTypes.bool,
  latestSearch: PropTypes.string
};

export default StoreLocatorMessage;
